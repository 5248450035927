.profile-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    padding: 5px;
}

.profile-container {
    display: flex;
    align-items: center;
}

.expand-button {
    cursor: pointer;
    color: blue;
    float: right;
    position: absolute;
    right: 10px;
    top: 60px;
}

.hidden {
    display: none;
}

.expand-icon {
    font-size: 20px;
    margin-right: 5px;
}

.titleSpan {
    width: 200px;
    display: inline-block;
}

.button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
}

.top-right {
    top: 10px;
    right: 10px;
}

.profile-container {
    display: flex;
    align-items: center;
    position: relative;
}

.profile-details {
    margin-left: 20px;
}

.Draftail-Editor {
    width: 100%;
}

.dropdown-container {
    color: #212529;
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem !important;
}

.dropdown-heading {
    height: 36px !important;
}

    .dropdown-heading .gray {
        color: #212529 !important;
    }

.btn-filter {
    padding: 0;
    background: transparent;
    color: black !important;
    border: none;
    background-color: transparent !important;
    border-color: transparent !important;
}
    .btn-filter::after {
        display: none;
    }

.dropdown-menu {
    min-width: 15rem;
} 

.field-disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.remove-setting-icon {
    position: absolute;
    right: -4px;
    bottom: 23px;
    width: auto;
    cursor: pointer;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 1.3rem;
}

.back-arrow{
    left: -50px;
    cursor: pointer;
    z-index: 11;
}

.modal-dialog{
    max-width: 70% !important;
}


